
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap');


body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
#top-bar {

  background-color: #3498db !important;

}*/

/*
.nav-item{
 color: #fff !important;

}

.nav-item:hover{
  color: black !important;
}

*/



.navbar {

  background-color: #fff !important;
  
}
/* 
.navbar-brand {
 font-size: 1.9rem;
  color: #565387 !important;

  <a className="navbar-brand" href="#"*>Hacking Truth</a>
  
}
*/


 .nav-item-social{
  
  margin: 0 8px ;
  margin-bottom: 5px; 
  /*padding-left: 3px;*/
  color: #000;
  


}

.nav-link-item {

  color: #000 !important;
  text-decoration: none;
 
 
}


#contact-info {
  color: rgb(248, 227, 227) !important;
  
 /* padding-right:  5px;*/
  
  

}


 .nav-link-item:hover {

  color: red  !important;
  text-shadow: 2px 2px 5px white;
  
}

.navbar-brand {
  width:180px;
  font-size: 24px;
  
   /*margin-block-start: 10%;*/
}
.navbar-brand img {
  height: 50px;
  /* put value of image height as your need */
  
}

.navbar a {
  font-size: 1.3rem;
  text-transform: capitalize;
  
}

.menu_active {
  font-weight: bold;
  border-bottom:  1px solid #565387;
}


.navbar a:hover{

  color: #3498db !important;
  
}

@media (max-width: 991px){

  .navbar-brand #contact-info{
    font-size: 1.5rem !important;
  }
}


#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}

#header h2{
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5ps;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;

}


#header .btn-get-started:hover{
  background: #3498db;
  color: #fff;

}

.header-img{
  text-align: right;
  border-radius: 2px solid green;
  
}



#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down{
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down{
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translate(-10px);
  }
}



/*contact start */


.contact_div {

  width: 100%;
  height: 75vh;
}

footer p {
  padding: 0;
  margin: 0;
}




/* reponsive code start*/

@media (max-width: 991px){
  #header {
    height: 80v;
    text-align: center;
  }

  #header .header-img{
    text-align: center;
  }

  #header .header-img img{
    width: 60%;
  }
}

@media (max-width: 768px){
  #header {
    margin-top: 20px;
  }

  #header h1 {
    font-size: 28px;
  }

  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  #header .header-img img{

    width: 70%;
  }

}


.card .btn-primary:hover {
  transition: 0.5ps;
  color: #3498db;
  
  
}

/* Transition to a bigger shadow on hover */
.card {
  
  box-shadow: 5px 5px 15px rgba(0,0,0,0.3);
}





@media (max-width: 768px){    /*  system */
.about-box  .about-section {
    
	width: 70%;
    
  }
}




.btn:hover{

  color: #3498db !important;
}



/*footer */

.social-item-link {
	color: #fff !important;
	text-decoration: none;
	
}


.social-item-link:hover {
	color: #fff !important;
}


/*
.map {
	 width:600;
	 height:450;
	 frameborder:0;
	 border:0; 


}
*/
